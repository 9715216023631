<template>
  <div id="supplier-details">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <b-modal
      id="delete-supplier-modal"
      v-model="showModal"
      title="Supplier Deletion Confirmation"
      @ok="doubleDeleteModal(true)"
    >
      <p class="my-2">Are you sure you would like to delete this supplier?</p>
    </b-modal>

    <b-modal
      id="double-delete-supplier-modal"
      v-model="showDoubleModal"
      ref="my-modal"
      title="Double Deletion Confirmation"
      @ok="confirmedDelete"
      :ok-disabled="blockDelete"
    >
      <p id="countdown">{{ count }}</p>
      <p class="my-2">
        Deleting a supplier could create unexpected errors if it is in use. Please be careful, only
        delete a supplier if you're certain it has never been used. Are you sure you want to delete
        the supplier?
      </p>
    </b-modal>

    <b-container>
      <h1>Supplier Details</h1>

      <div class="d-flex justify-content-center mb-3" v-if="loading">
        <Busy primary text="Loading..." />
      </div>
      <div v-else>
        <!-- Action Button -->
        <b-dropdown text="Actions">
          <!-- Suppliers Search -->
          <b-dropdown-item :to="{ name: 'get-suppliers' }">
            <font-awesome-icon :icon="['fas', 'table']" class="text-muted mr-2" />Suppliers Search
          </b-dropdown-item>

          <!-- Spotlight rules search -->
          <b-dropdown-item
            v-if="isAdmin"
            :to="{ name: 'spotlight-rules', query: { q: supplier.name } }"
          >
            <font-awesome-icon :icon="['fas', 'whale']" class="text-muted mr-2" />Spotlight Rules
          </b-dropdown-item>

          <div v-if="isAdmin">
            <b-dropdown-divider />

            <!-- Supplier Edit -->
            <b-dropdown-item
              :to="{ name: 'admin-supplier-edit', params: { supplierId: this.routeId } }"
            >
              <font-awesome-icon :icon="['far', 'edit']" class="text-muted mr-2" />Edit supplier
            </b-dropdown-item>

            <!-- Delete Consent List -->
            <b-dropdown-item @click="deleteHandler()">
              <font-awesome-icon :icon="['fas', 'trash']" class="text-muted mr-2" />Delete supplier
            </b-dropdown-item>

            <!-- Supplier Create -->
            <b-dropdown-item :to="{ name: 'admin-supplier-create' }">
              <font-awesome-icon :icon="['fas', 'file-upload']" class="text-muted mr-2" />New
              supplier
            </b-dropdown-item>
          </div>
        </b-dropdown>

        <b-row class="my-4 d-flex justify-content-center" align-v="center">
          <b-col cols="12" sm="10" md="8" lg="4" offset-lg="1" class="text-center">
            <b-img
              class="my-4 px-5"
              rounded
              :src="supplier.logos3url"
              fluid
              alt="Supplier logo not found"
            ></b-img>
            <TrustPilot
              v-if="trustPilot.score > 0"
              :score="trustPilot.score"
              :reviews="trustPilot.reviews"
            />
          </b-col>

          <b-col cols="12" lg="6" offset-lg="1">
            <div class="px-5 px-lg-0">
              <h5><b> Name: </b> {{ supplier.name }}</h5>
              <h5><b> Service types: </b> {{ formatCategories() }}</h5>
              <h5 v-if="supplier.companyHouseNumber">
                <b> Companies house number: </b> {{ supplier.companyHouseNumber }}
              </h5>
              <h5 v-if="isAdmin && spotlightRules && spotlightRules.length > 0">
                <b> Spotlight matching terms: </b>
                <ul>
                  <li v-for="(rule, index) in spotlightRules" :key="index" class="pt-1">
                    <p v-if="rule.sectors && rule.matchingTerms" class="m-0">
                      {{ rule.sectors.join(", ") }}: {{ rule.matchingTerms.join(", ") }}
                    </p>
                  </li>
                </ul>
              </h5>
            </div>
          </b-col>
        </b-row>

        <b-row class="d-flex justify-content-center">
          <b-col cols="12" sm="11" lg="6" class="px-5">
            <div class="mb-2">
              <b-button
                class="reducer-button secondary w-100"
                pill
                v-if="this.socialMedia.facebookUrl"
                :href="this.socialMedia.facebookUrl"
                variant="outline-secondary"
              >
                <font-awesome-icon
                  id="facebook-icon"
                  :icon="['fab', 'facebook-f']"
                  class="fa-2x float-left"
                  transform="shrink-3.5"
                  :mask="['fas', 'circle']"
                />
                <p class="m-0 pt-1 pr-4">Facebook</p>
              </b-button>

              <b-button
                class="reducer-button secondary w-100"
                pill
                v-if="this.socialMedia.twitterUrl"
                :href="this.socialMedia.twitterUrl"
                variant="outline-secondary"
              >
                <font-awesome-icon
                  id="twitter-icon"
                  :icon="['fab', 'twitter']"
                  class="fa-2x float-left"
                  transform="shrink-3.5"
                  :mask="['fas', 'circle']"
                />
                <p class="m-0 pt-1 pr-4">Twitter</p>
              </b-button>

              <b-button
                class="reducer-button secondary w-100"
                pill
                v-if="this.socialMedia.linkedInUrl"
                :href="this.socialMedia.linkedInUrl"
                variant="outline-secondary"
              >
                <font-awesome-icon
                  id="linkedin-icon"
                  :icon="['fab', 'linkedin-in']"
                  class="fa-2x float-left"
                  transform="shrink-3.5"
                  :mask="['fas', 'circle']"
                />
                <p class="m-0 pt-1 pr-4">LinkedIn</p>
              </b-button>

              <b-button
                class="reducer-button secondary w-100"
                pill
                v-if="this.socialMedia.websiteUrl"
                :href="this.socialMedia.websiteUrl"
                variant="outline-secondary"
              >
                <font-awesome-icon
                  id="website-icon"
                  :icon="['fas', 'globe-americas']"
                  class="fa-2x float-left"
                />
                <p class="m-0 pt-1 pr-4">Website</p>
              </b-button>

              <b-button
                class="reducer-button secondary w-100"
                pill
                v-if="this.trustPilot.url"
                :href="this.trustPilot.url"
                variant="outline-secondary"
              >
                <font-awesome-icon
                  id="trustpilot-icon"
                  :icon="['fad', 'star-half']"
                  class="fa-2x float-left"
                  transform="shrink-3.5"
                  :mask="['fas', 'circle']"
                />
                <p class="m-0 pt-1 pr-4">Trust Pilot</p>
              </b-button>
            </div>

            <b-card v-if="isAdmin" id="admin-card" class="p-2 reducer-box-shadow">
              <span class="boldSpan">Created By:</span>
              <p>{{ supplier.createdByEmail }}</p>
              <span class="boldSpan">Created Date:</span>
              <p>{{ formatDate(supplier.createdDate) }}</p>

              <span class="boldSpan">Updated By:</span>
              <p>{{ supplier.lastUpdatedByEmail }}</p>
              <span class="boldSpan">Updated Date:</span>
              <p>{{ formatDate(supplier.updatedDate) }}</p>

              <span class="boldSpan">TrustPilot update date:</span>
              <p class="m-0">{{ formatDate(trustPilot.lastUpdate) }}</p>
            </b-card>
          </b-col>

          <b-col cols="12" lg="6" class="mt-3 px-3">
            <div v-if="supplier.shortDescription" class="text-justify">
              <h5>Short Description</h5>
              <p>{{ supplier.shortDescription }}</p>
            </div>

            <div v-if="supplier.longDescription" class="text-justify">
              <h5>Description</h5>
              <p>{{ supplier.longDescription }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#supplier-details {
  .reducer-button {
    margin-bottom: 1rem;

    #linkedin-icon {
      color: #007bb6;
    }

    #facebook-icon {
      color: #3b579d;
    }

    #twitter-icon {
      color: #2caae1;
    }

    #website-icon {
      color: $color-pink-main;
    }

    #trustpilot-icon {
      color: $color-green;
    }
  }

  #admin-card {
    border-radius: 18px;
  }

  #countdown {
    font-size: 25px;
    font-style: "bold";
    text-align: center;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";
import { EventBus } from "@/components/eventbus";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import TrustPilot from "@/components/TrustPilot";

export default {
  name: "SupplierDetails",
  components: { Busy, TrustPilot },
  data() {
    return {
      title: "Supplier Details",
      loading: true,
      isAdmin: false,
      supplier: null,
      spotlightRules: [],
      socialMedia: [
        { twitterUrl: null },
        { facebookUrl: null },
        { websiteUrl: null },
        { linkedInUrl: null }
      ],

      trustPilot: [{ url: null }, { score: 0 }, { reviews: 0 }],

      showModal: false,
      showDoubleModal: false,
      blockDelete: true,
      count: 0,
      routeId: null
    };
  },
  created() {
    this.refreshAdmin();
    this.fetchData();
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    formatCoboldCase: FormatHelper.formatCoboldCase,
    async refreshAdmin() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
    },
    async fetchData() {
      this.routeId = this.$route.params.supplierId;
      this.getSupplierData(this.routeId);
    },

    async getSupplierData(routeId) {
      var summarised = false;
      var supplierIds = new Array();
      supplierIds.push(routeId);

      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}suppliers?spotlight=true&summarised=${summarised}&supplierIds=${supplierIds}`
        );
        if (response.data.status === "success") {
          var firstElement = Object.keys(response.data.suppliers)[0];
          this.supplier = response.data.suppliers[firstElement];

          if (this.supplier.categories) {
            for (let j = 0; j < this.supplier.categories.length; j++) {
              this.supplier.categories[j] = this.formatCoboldCase(this.supplier.categories[j]);
            }
          }

          if (response.data.suppliers[firstElement].socialMedia)
            this.socialMedia = response.data.suppliers[firstElement].socialMedia;
          if (response.data.suppliers[firstElement].trustPilot)
            this.trustPilot = response.data.suppliers[firstElement].trustPilot;
          if (response.data.spotlightRules)
            this.spotlightRules = response.data.spotlightRules[firstElement];
          Console.log(response);
        } else {
          this.showMessage("A problem occured while extracting the supplier data.", "warning");
        }
      } catch (error) {
        this.showMessage("A problem occured while extracting the supplier data.", "warning");
        Console.error(error);
      } finally {
        this.loading = false;
      }
    },

    //DELETE SUPPLIER BY ID
    countDownTimer(countdown) {
      this.blockDelete = true;
      this.count = 10;
      if (countdown > 0) {
        setTimeout(() => {
          countdown -= 1;
          this.countDownTimer(countdown);
        }, 1000);
        this.count = countdown;
      } else {
        this.blockDelete = false;
      }
    },

    doubleDeleteModal(value) {
      this.showDoubleModal = value;

      if (value == true) {
        this.countDownTimer(10);
      }
    },

    deleteHandler() {
      this.showModal = true;
    },
    async confirmedDelete() {
      try {
        const client = await ApiHelper.http();
        let response = await client.delete(`${ApiHelper.endPoint()}suppliers/${this.routeId}`);
        if (response.data.status === "success") {
          Console.log(response);
          this.$router.push({ name: "get-suppliers" });
        } else {
          this.showMessage("There was a problem deleting the supplier.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem deleting the supplier.", "warning");
      }
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    formatCategories() {
      if (this.supplier.broker) {
        return "Broker";
      } else if (this.supplier.categories) {
        let result = null;
        for (var i = 0; i < this.supplier.categories.length; i++) {
          if (i == 0) result = this.supplier.categories[i];
          else result = result + ", " + this.supplier.categories[i];
        }
        return result;
      }
    }
  }
};
</script>
