<template>
  <div class="trustpilot-stars">
    <div class="text-center">
      <b-row class="mb-4 d-flex justify-content-center">
        <!-- 1 -->
        <font-awesome-icon
          v-if="parseFloat(score) < 0.5"
          :icon="['fas', 'star']"
          class="star empty-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 0.5 && parseFloat(score) < 1"
          :icon="['fad', 'star-half']"
          class="star full-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 1"
          :icon="['fas', 'star']"
          class="star full-star fa-3x"
        />
        <!-- 2 -->
        <font-awesome-icon
          v-if="parseFloat(score) < 1.5"
          :icon="['fas', 'star']"
          class="star empty-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 1.5 && parseFloat(score) < 2"
          :icon="['fad', 'star-half']"
          class="star full-star fa-3x"
        />

        <font-awesome-icon
          v-if="parseFloat(score) >= 2"
          :icon="['fas', 'star']"
          class="star full-star fa-3x"
        />
        <!-- 3 -->
        <font-awesome-icon
          v-if="parseFloat(score) < 2.5"
          :icon="['fas', 'star']"
          class="star empty-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 2.5 && parseFloat(score) < 3"
          :icon="['fad', 'star-half']"
          class="star full-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 3"
          :icon="['fas', 'star']"
          class="star full-star fa-3x"
        />
        <!-- 4 -->
        <font-awesome-icon
          v-if="parseFloat(score) < 3.5"
          :icon="['fas', 'star']"
          class="star empty-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 3.5 && parseFloat(score) < 4"
          :icon="['fad', 'star-half']"
          class="star full-star fa-3x"
        />

        <font-awesome-icon
          v-if="parseFloat(score) >= 4"
          :icon="['fas', 'star']"
          class="star full-star fa-3x"
        />
        <!-- 5 -->
        <font-awesome-icon
          v-if="parseFloat(score) < 4.5"
          :icon="['fas', 'star']"
          class="star empty-star fa-3x"
        />
        <font-awesome-icon
          v-if="parseFloat(score) >= 4.5 && parseFloat(score) < 5"
          :icon="['fad', 'star-half']"
          class="star full-star fa-3x"
        />

        <font-awesome-icon
          v-if="parseFloat(score) == 5"
          :icon="['fas', 'star']"
          class="star full-star fa-3x"
        />
      </b-row>

      <span>Trust Pilot score:</span>
      <p>
        <span>{{ score }}</span>
        out of 5 ({{ reviews }} reviews)
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
.trustpilot-stars {
  .star {
    max-width: 19%;
    margin-right: 1%;
  }
  .full-star {
    color: $color-pink-main;
  }
  .empty-star {
    color: $color-pink-darker2;
  }
  
  span {
    font-weight: bold;
  }
}
</style>

<script>
export default {
  name: "TrustPilot",
  props: {
    url: { type: String, default: null },
    score: Number,
    reviews: Number
  }
};
</script>

